.infoOption {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: center;
    background-color: #dfede6;
    padding: 5px; /* Espacio dentro del elemento */
    margin-bottom: 20px;
    border-radius: 5px; /* Bordes redondeados opcionales */
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1); /* Sombra opcional para darle un efecto de tarjeta */
    height: 368px;
}

.sistemaImg {
    max-height: 60%;
    width: auto;
    object-fit: contain;
}

.infoBox {
    background-color: #f6f6ff;
    padding: 10px;
    margin: 10px 0;
    border-radius: 5px;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    margin-top: auto;
}