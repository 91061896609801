.spacing {
    margin-top: 20px;
    margin-bottom: 60px;
}

.col-md-6 {
    flex-direction: row;
    align-items: center;
}

.title {
    color: #67a883;
    font-size: 48px;
    font-weight: 500;
}