.climate {}

.pregunta {
    font-size: large;
    padding-left: 80px !important;
    padding-top: 70px;
    text-align: left;
}

.opciones {
    margin-top: 20px !important;
}

.formulario {
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.opcionSeleccionada {
    background-color: orange;
    border-radius: 10px;
    color: #fbfaec;
    padding: 5px;
    margin: 5px 0 5px 0;
    cursor: pointer;
}

.opcionNoSeleccionada {
    background-color: #ffffff;
    border-radius: 10px;
    color: #000000;
    padding: 5px;
    margin: 5px 0 5px 0;
    cursor: pointer;
}