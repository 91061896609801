.sistema {
    padding-top: 30px;
}

.sistemaTitulo {
    font-weight: 900;
    font-size: 35px;
    text-shadow: 1px 1px 0 #616aa5, -1px -1px 0 #616aa5, 1px -1px 0 #616aa5, -1px 1px 0 #616aa5;
    letter-spacing: 2px;
    margin-top: 20px !important;
    margin-left: 20px !important;
    margin-bottom: 15px;
    color: #616aa5;
    text-align: left;
}

.sistemaApartadoTitulo {
    background-color: #c0c7e5;
    padding: 10px;
    margin: 10px 0;
    flex-shrink: 0;
    font-weight: bold;
    margin-left: 10px;
    height: 40px;
}

.sistemaNota {
    font-weight: bold;
    margin-left: 10px;
}

.usos {
    display: flex;
    gap:10px;
    align-items: center;
}


.atras {
    margin: 20px 20px 20px 20px !important;
}