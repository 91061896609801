.subpanel {
    background-color: #f9f8d9;
    padding: 20px;
    border-radius: 30px;
}

.color1 {
    width: 10px;
    height: 25px;
    border-radius: 50%;
    background-color: #cbc04a;
}

.color2 {
    width: 10px;
    height: 25px;
    border-radius: 50%;
    background-color: #dfde7c;
}

.color3 {
    width: 10px;
    height: 25px;
    border-radius: 50%;
    background-color: #ac5372;
}

.color4 {
    width: 10px;
    height: 25px;
    border-radius: 50%;
    background-color: #77bf95;
}

.color5 {
    width: 10px;
    height: 25px;
    border-radius: 50%;
    background-color: #4a96cd;
}

.nota {
    font-size: 12px;
    color: #666;
    font-weight: bold;
}