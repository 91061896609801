.description {
    text-align: left;
    font-weight: bold;
    margin-bottom: 10px;
}

.system {
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    padding-right: 30px;
}