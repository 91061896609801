.infoOption {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: center;
    background-color: #ebedfa;
    padding: 5px; /* Espacio dentro del elemento */
    margin-bottom: 20px;
    border-radius: 5px; /* Bordes redondeados opcionales */
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1); /* Sombra opcional para darle un efecto de tarjeta */
    height: 368px;
    border: 3px solid #6b74ac;
    cursor: pointer;
}

.sistemaImg {
    max-height: 60%;
    width: auto;
    object-fit: contain;
}

.infoBox {
    background-color: #ebedfa;
    color: #6b74ac;
    font-weight: bold;
    padding: 10px;
    margin: 10px 0;
    margin-top: auto;
}

.nombreSistema {
    color: #6b74ac;
    font-weight: bold;
    margin: 10px 0 10px 0;
    font-size: 20px;
}