.module {
    background-color: rgba(255, 255, 255, 0.8);
    margin-top: 50px !important;
    margin-bottom: 20px;
    padding: 30px;
    border-radius: 40px;
}

.objective {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    height: 100%;
    text-align: left;
}

.objectiveTitleBlack {
    font-weight: 900;
    font-size: 35px;
    line-height: 1.2;
    text-shadow: 1px 1px 0 #000, -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000;
}

.objectiveTitleBlue1 {
    font-weight: 900;
    font-size: 35px;
    line-height: 1.2;
    color: #616aa5;
    text-shadow: 1px 1px 0 #616aa5, -1px -1px 0 #616aa5, 1px -1px 0 #616aa5, -1px 1px 0 #616aa5;
}

.objectiveTitleBlue2 {
    font-weight: 900;
    font-size: 35px;
    line-height: 1.2;
    color: #616aa5;
    text-shadow: 1px 1px 0 #616aa5, -1px -1px 0 #616aa5, 1px -1px 0 #616aa5, -1px 1px 0 #616aa5;
}