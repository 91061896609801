.background {
    background-color: #dedeff;
    background-image: url("images/background.png");
    background-size: cover;
    padding-bottom: 40px;
}

.demandaTitle {
    text-align: center;
    font-weight: 900;
    font-size: 35px;
    line-height: 1.2;
    text-shadow: 1px 1px 0 #000, -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000;
    margin-bottom: 15px;
}

.demandaSubtitle {
    text-align: center;
    width: 60% !important;
    margin: 0 auto;
}

.demandaFondoTransparente {
    background-color: rgba(251, 250, 236, 0.5);
    margin-top: 50px !important;
    margin-bottom: 20px;
    padding: 40px;
    border-radius: 40px;
}

.pregunta {
    font-size: large;
    padding-top: 70px;
}

.siguiente {
    padding-top: 50px;
}