.background {
    background-color: #dedeff;
    background-image: url("images/background.png");
    background-size: cover;
    padding-bottom: 40px;
}

.informacionTitle {
    text-align: right;
    font-weight: 900;
    font-size: 35px;
    line-height: 1.2;
    text-shadow: 1px 1px 0 #000, -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000;
    letter-spacing: 2px;
    margin-bottom: 15px;
}

.informacionSubtitle {
    margin: 0 auto;
    font-weight: normal;
    font-size: 16px;
    line-height: 1.2;
    text-align: right;
    padding-left: 30%;
    margin-bottom: 25px;
}

#conocerMejorOpcion {
    margin: 0 auto;
}
