.gradiente {
    padding: 20px;
}

.rowSpacing {
    margin-top: 10px;
    margin-bottom: 10px;
}

.titleSection {
    font-weight: bold;
    font-size: 20px;
    flex-direction: row;
}

.iconWrapper {
    margin-right: 10px;
}

.content {
    margin-bottom: 20px;
    font-size: larger;
}

.highlight {
    color: #67a883;
}
