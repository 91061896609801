/* Header.module.css */

.topSpace {
    margin-top: 10px;
}

.enFila {
    flex-direction: row;
}

.w35 {
    width: 35px;
}

.enlaceHeader {
    color: #171717;
    font-size: 14px;
    text-decoration: none; /* Sin subrayado */
    font-weight: bold;
}