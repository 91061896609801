.opcion {
    cursor: pointer;
}

.descripcion {
    text-align: left;
}

.opcionSeleccionada {
    background-color: #4a96cd;
    border-radius: 10px;
    color: #fbfaec;
    padding: 5px;
    margin: 5px 0 5px 0;
    cursor: pointer;
}

.opcionNoSeleccionada {
    background-color: #ffffff;
    border-radius: 10px;
    color: #000000;
    padding: 5px;
    margin: 5px 0 5px 0;
    cursor: pointer;
}