.title {
    margin-top: 20px;
    font-weight: bold;
    font-size: 24px; /* Ajusta el tamaño según el que necesites */
    color: #616aa5; /* Este es un color anaranjado que se asemeja al de la imagen */
    text-transform: uppercase; /* Convierte el texto a mayúsculas */
    text-align: left;
    margin-bottom: 20px;
}

.emisiones {
    margin-bottom: 20px;
}