.title {
    margin-top: 40px !important;
    margin-bottom: 20px;
    font-weight: 900;
    font-size: 35px;
    line-height: 1.2;
    text-shadow: 1px 1px 0 #000, -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000;
}

.introduction1 {
    margin-bottom: 20px;
}

.introduction2 {
    font-weight: bold;
    margin-bottom: 40px;
    color: #663658 !important;
}