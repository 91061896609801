.pregunta {
    font-size: large;
    padding-top: 70px;
    padding-left: 80px !important;
    text-align: left;
}

.formulario {
    padding-left: 10%;
    padding-top: 5%;
}

