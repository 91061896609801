.title {
    color: #a74d65;
    font-size: 48px;
    font-weight: 500;
}

.description {
    margin-top: 10px;
    margin-bottom: 20px;
}

ul {
    text-align: left;
}

.spacing {
    margin-top: 30px;
    margin-bottom: 40px;
}