.subpanel {
    background-color: #f1f1f1;
    padding: 20px;
    border-radius: 30px;
}

.color1 {
    width: 10px;
    height: 25px;
    border-radius: 50%;
    background-color: #616aa5;
}

.color2 {
    width: 10px;
    height: 25px;
    border-radius: 50%;
    background-color: #aa90c8;
}

.color3 {
    width: 10px;
    height: 25px;
    border-radius: 50%;
    background-color: #ca80b3;
}

.nota {
    font-size: 12px;
    color: #666;
    font-weight: bold;
}