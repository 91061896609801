.flexContainer {
    display: flex;
    justify-content: space-between;
}

.flexItem {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: end;
    cursor: pointer;
}

.monigotes {
    padding-top: 30px;
}