.enlaceFooter {
    color: white; /* Letras en blanco */
    text-decoration: none; /* Sin subrayado */
    margin-bottom: 5px;
    font-weight: 500;
    font-family: "Heebo", Sans-serif;
}

.contacto {
    font-weight: 500;
    font-family: "Heebo", Sans-serif;
}

.compensacion {
    margin-top: 50px;
    font-size: 14px;
    color: #A6F045;
    font-weight: 500;
    font-family: "Heebo", Sans-serif;
}

.divisor {
    margin-top: 30px;
    display: block; /* Asegura que el span se comporte como un bloque */
    width: 100%; /* Ancho completo del contenedor */
    height: 1px; /* Altura de la línea */
    background-color: #FFFFFF; /* Color de fondo blanco */
}

.enlaceCreditos {
    color: white; /* Letras en blanco */
    text-decoration: none; /* Sin subrayado */
    margin-bottom: 5px;
    font-weight: 500;
    font-family: "Heebo", Sans-serif;
    font-size: 13px;
    margin-right: 10px;
}