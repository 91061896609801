.panel {
    background-color: #ffffff;
    padding: 20px;
    border-radius: 30px;
}

.accesoPaneles {
    padding-bottom: 20px;
    text-align: center;
}

.accesoPanel {
    margin: 0px 10px 0px 10px;
    background-color: #616aa5;
    border-radius: 15px;
    cursor: pointer;
    color: white;
}