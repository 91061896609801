/* Contenedor general */
.costBar {
    background-color: rgba(0, 0, 0, 0); /* Transparente */
    border-radius: 5px;
    overflow: hidden;
    height: 20px;
    margin-bottom: 10px;
}

/* Contenedor interno transparente */
.innerBar {
    width: 100%; /* Ajusta este valor para controlar el porcentaje que ocupa la barra interna */
    background-color: rgba(0, 0, 0, 0); /* Transparente */
    display: flex;
    height: 100%;
}

.tooltip {
    text-align: left;
}

.tooltipTitle {
    font-weight: bold;
}