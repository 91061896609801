.contexto {
    margin-bottom: 20px;
}

.module {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    background-color: #fbf2f7;
}

.title {
    font-weight: bold;
    text-align: left;
    font-size: 24px;
    color: #AC5372;
    margin-bottom: 20px;
}